/* Pygments theme exported from Emacs */

/* Palette
#2c3e50  foreground
#ecf0f1  background
#16a085  builtin
#7f8c8d  comment
#e67e22  constant
#7f8c8d  doc
#34495e  func
#d98c10  keyword
#d98c10  regex
#0a74b9  string
#2492db  type
#8e44ad  variable
#d35400  warning
*/

/* Style */
.highlight { color: #2c3e50; background-color: #ecf0f1; }
.highlight .c { color: #7f8c8d; }
.highlight .err { color: #d35400; }
.highlight .g { color: #2c3e50; }
.highlight .k { color: #d98c10; }
.highlight .l { color: #2c3e50; }
.highlight .n { color: #2c3e50; }
.highlight .o { color: #34495e; }
.highlight .x { color: #e67e22; }
.highlight .p { color: #2c3e50; }
.highlight .cm { color: #7f8c8d; }
.highlight .cp { color: #34495e; }
.highlight .c1 { color: #7f8c8d; }
.highlight .cs { color: #34495e; }
.highlight .gd { color: #0a74b9; }
.highlight .ge { color: #2c3e50; font-style: italic; }
.highlight .gr { color: #d35400; }
.highlight .gh { color: #e67e22; }
.highlight .gi { color: #34495e; }
.highlight .go { color: #2c3e50; }
.highlight .gp { color: #2c3e50; }
.highlight .gs { color: #2c3e50; font-weight: bold; }
.highlight .gu { color: #e67e22; }
.highlight .gt { color: #2c3e50; }
.highlight .kc { color: #e67e22; }
.highlight .kd { color: #d98c10; }
.highlight .kn { color: #34495e; }
.highlight .kp { color: #34495e; }
.highlight .kr { color: #d98c10; }
.highlight .kt { color: #2492db; }
.highlight .ld { color: #2c3e50; }
.highlight .m { color: #0a74b9; }
.highlight .s { color: #0a74b9; }
.highlight .na { color: #2c3e50; }
.highlight .nb { color: #16a085; }
.highlight .nc { color: #2492db; }
.highlight .no { color: #e67e22; }
.highlight .nd { color: #2492db; }
.highlight .ni { color: #e67e22; }
.highlight .ne { color: #e67e22; }
.highlight .nf { color: #34495e; }
.highlight .nl { color: #2c3e50; }
.highlight .nn { color: #2c3e50; }
.highlight .nx { color: #2c3e50; }
.highlight .py { color: #2492db; }
.highlight .nt { color: #2492db; }
.highlight .nv { color: #8e44ad; }
.highlight .ow { color: #34495e; }
.highlight .w { color: #2c3e50; }
.highlight .mf { color: #0a74b9; }
.highlight .mh { color: #0a74b9; }
.highlight .mi { color: #0a74b9; }
.highlight .mo { color: #0a74b9; }
.highlight .sb { color: #7f8c8d; }
.highlight .sc { color: #0a74b9; }
.highlight .sd { color: #7f8c8d; }
.highlight .s2 { color: #0a74b9; }
.highlight .se { color: #e67e22; }
.highlight .sh { color: #7f8c8d; }
.highlight .si { color: #0a74b9; }
.highlight .sx { color: #0a74b9; }
.highlight .sr { color: #d98c10; }
.highlight .s1 { color: #0a74b9; }
.highlight .ss { color: #0a74b9; }
.highlight .bp { color: #16a085; }
.highlight .vc { color: #2492db; }
.highlight .vg { color: #8e44ad; }
.highlight .vi { color: #8e44ad; }
.highlight .il { color: #0a74b9; }
